import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

const styles = {
  root: (fullHeight: boolean, width?: string) =>
    css({
      display: "flex",
      flexDirection: "row",
      flex: "1 1 auto",
      justifyContent: "space-between",
      boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24)",
      maxWidth: width || undefined,
      minWidth: width || undefined,
      height: fullHeight ? "100%" : undefined,
    }),

  main: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    margin: "16px",
  }),

  content: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  }),

  title: (t: Theme) =>
    css({
      color: t.colors.brand_variant,
    }),

  image: (imgUrl: string) =>
    css({
      flex: "1 1 auto",
      maxWidth: "444px",
      minHeight: "320px",
      background: `url(${imgUrl}) no-repeat center center`,
      backgroundSize: "cover",
    }),
}

export default styles
