import GoogleTagManager from "react-gtm-module"
import { HeaderButton, SocialLinkItem } from "@basset-la/components-commons/dist/models/types"
import Header from "@basset-la/components-commons/dist/components/Header"
import Footer from "@basset-la/components-commons/dist/components/Footer"
import { useEffect } from "react"
import ReactGA from "react-ga"
import styles from "./Wrapper.styles"
import { useAuth0 } from "@auth0/auth0-react"
import { useAuthUser, useConfig } from "@basset-la/components-commons"

interface Props {
  children?: React.ReactNode
}

const Wrapper: React.FC<Props> = ({ children }) => {
  const { config, availableProducts } = useConfig()
  const { agency } = useAuthUser()
  const auth0 = useAuth0()

  useEffect(() => {
    if (config.google_tag_manager_id) {
      GoogleTagManager.initialize({ gtmId: config.google_tag_manager_id })
    }
    if (config.analytics_id) {
      ReactGA.initialize(config.analytics_id)
    }
  }, [config.google_tag_manager_id, config.analytics_id])

  const handleLogout = () => {
    auth0.logout({ returnTo: `${window.location.origin}/auth/login` })
  }

  const handleAccessBackOffice = () => {
    const url = process.env.REACT_APP_BO_URL || ""
    window.open(url, "_blank")?.focus()
  }

  const buildSocialLinkItems = (): SocialLinkItem[] => {
    if (!config.brand.contact_data || !config.brand.contact_data.social_media) {
      return []
    }
    const social = config.brand.contact_data.social_media
    const items: SocialLinkItem[] = []
    if (social.facebook) items.push({ type: "facebook", url: social.facebook })
    if (social.instagram) items.push({ type: "instagram", url: social.instagram })
    if (social.linkedin) items.push({ type: "linkedin", url: social.linkedin })
    if (social.tiktok) items.push({ type: "tiktok", url: social.tiktok })
    if (social.twitter) items.push({ type: "twitter", url: social.twitter })
    if (social.youtube) items.push({ type: "youtube", url: social.youtube })
    return items
  }

  const socialLinkItems = buildSocialLinkItems()
  const phone = config.brand.contact_data?.phone_numbers ? config.brand.contact_data.phone_numbers[0] : undefined

  const drawHeader = () => {
    const activeProducts: HeaderButton[] = []

    if (availableProducts.length > 0) {
      const productsSet = new Set(availableProducts)
      if (productsSet.has("FLIGHTS")) activeProducts.push({ type: "flights", path: "/?product=flights" })
      if (productsSet.has("HOTELS")) activeProducts.push({ type: "accommodations", path: "/?product=hotels" })
      if (productsSet.has("COMBINED_PRODUCTS"))
        activeProducts.push({ type: "trip", path: "/?product=combined_products" })
      if (productsSet.has("CARS")) activeProducts.push({ type: "cars", path: "/?product=cars" })
      if (productsSet.has("TRAVEL_INSURANCES"))
        activeProducts.push({ type: "travel_insurances", path: "/?product=travel_insurances" })

      if (config.agency_name && config.brand.logo) {
        return (
          <Header
            flow="availability"
            company={config.agency_name}
            logo={config.brand.logo}
            products={activeProducts}
            development={process.env.REACT_APP_SHOW_DEVELOPMENT_ALERT === "true"}
            phone={phone}
            socialNetworks={socialLinkItems}
            isAuthenticated={config.require_authentication}
            showBackOfficeOption={!!agency}
            onLogout={handleLogout}
            onAccessBackOffice={handleAccessBackOffice}
          />
        )
      }
    }
  }

  const drawFooter = () => {
    return (
      <Footer
        phones={config.brand.contact_data?.phone_numbers || []}
        emails={config.brand.contact_data?.emails || []}
        socialNetworks={socialLinkItems}
      />
    )
  }

  return (
    <div>
      {config.header !== "" && <div dangerouslySetInnerHTML={{ __html: config.header }} />}
      {config.header === "" && drawHeader()}

      <div className={styles.root}>
        <div className={styles.main}>{children}</div>
      </div>

      {!!config!.footer && <div dangerouslySetInnerHTML={{ __html: config!.footer }} />}
      {config!.footer === "" && drawFooter()}
    </div>
  )
}

export default Wrapper
