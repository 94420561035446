import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

const styles = {
  root: (theme: Theme) =>
    css({
      padding: "24px",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      color: theme.colors.text_white,
      borderRadius: "4px",
      gap: "24px",
      margin: "64px auto",
      width: "70%",
    }),

  content: css({
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  }),

  title: (theme: Theme) =>
    css({
      color: theme.colors.brand_primary,
    }),

  image: (theme: Theme) =>
    css({
      "&.MuiSvgIcon-root": {
        width: "96px",
        height: "96px",
        fill: theme.colors.brand_darker,
      },
    }),
}

export default styles
