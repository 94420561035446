import ls from "localstorage-ttl"
import packageJson from "../../package.json"
import { AdvertisingInfo } from "../utils/types/advertising"
import { Config } from "@basset-la/components-commons/dist/models/config"

const getAdvertisingCacheKey = () => {
  const domain = process.env.NODE_ENV === "development" ? process.env.REACT_APP_DEV_DOMAIN : window.location.hostname
  return "advertising_" + packageJson.version + domain
}

const getStorageKey = (key) => {
  try {
    return ls.get(key)
  } catch (e) {
    return null
  }
}

const saveAdvertising = (advertising) => {
  try {
    const ttl = parseInt(process.env.REACT_APP_ADVERTISING_TTL || "300000", 300000)
    ls.set(getAdvertisingCacheKey(), JSON.stringify(advertising), ttl)
  } catch (e) {}
}

export const getAdvertisingFromUrl = async (config: any): Promise<AdvertisingInfo> => {
  const url = process.env.REACT_APP_ADVERTISING_API_URI
  const apiKey = process.env.REACT_APP_API_KEY
  if (!url) throw new Error("missing key REACT_APP_ADVERTISING_API_URI")
  if (!apiKey) throw new Error("missing key REACT_APP_API_KEY")
  const res = await fetch(`${url}/banners/CARS_RESULTS?channel=WEB&site=${config.country}&source=${config.source}`, {
    headers: {
      "x-client-id": config.agency_id,
      "x-api-key": apiKey,
    },
  })
  if (res.ok) {
    const result = (await res.json()) as AdvertisingInfo
    return result
  }
  throw new Error("Failed to get advertising")
}

export const getAdvertising = async (config: Config): Promise<AdvertisingInfo> => {
  const cacheKey = getAdvertisingCacheKey()
  const cachedAdvertising = getStorageKey(cacheKey)
  if (cachedAdvertising != null) {
    try {
      const adv = JSON.parse(cachedAdvertising)
      return new Promise((resolve) => {
        resolve(adv)
      })
    } catch (error) {
      console.error(error)
    }
  }

  const adv = await getAdvertisingFromUrl(config)
  saveAdvertising(adv)
  return adv
}
