import React from "react"
import { useTheme } from "@basset-la/themed-components"
import CarClusterWeb from "@basset-la/components-cars/dist/components/CarClusterWeb"
import styles from "./ClusterList.styles"
import { Cluster, Rate } from "@basset-la/components-cars/dist/models"
import InfiniteScroll from "./InfiniteScroll"

interface Props {
  clusters: Cluster[]
  cancellationHours?: number
  isMobile: boolean
  isLoading: boolean
  onPaginatedSearch: () => void
  onViewDetails: (r: Rate) => void
}

const ClusterList: React.FC<Props> = ({
  clusters,
  cancellationHours,
  isMobile,
  isLoading,
  onViewDetails,
  onPaginatedSearch,
}) => {
  const theme = useTheme()

  const handlePaginatedSearch = () => {
    onPaginatedSearch()
  }

  return (
    <InfiniteScroll isLoading={isLoading} onPaginatedSearch={handlePaginatedSearch}>
      {clusters.map((cluster: Cluster, i) => {
        return (
          <div key={i} className={styles.cluster(theme)}>
            <CarClusterWeb
              cluster={cluster}
              isMobile={isMobile}
              cancellationHours={cancellationHours}
              onViewDetails={onViewDetails}
            />
          </div>
        )
      })}
    </InfiniteScroll>
  )
}

export default ClusterList
