import { css } from "@emotion/css"

const styles = {
  root: (isMobile: boolean) =>
    css({
      display: "flex",
      flexDirection: !isMobile ? "row" : "column",
      gap: "16px",
      padding: !isMobile ? "24px" : "8px",
      width: "100%",
      justifyContent: "space-between",
    }),

  content: css({
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    width: "100%",
  }),

  contentRow: css({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    gap: "16px",
  }),

  contentRowEquipments: css({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "start",
    gap: "16px",
  }),

  contentRowItem: css({
    flex: "1 1 auto",
  }),

  priceboxContainer: (isMobile: boolean) =>
    css({
      width: !isMobile ? "432px" : "undefined",
      maxWidth: !isMobile ? "432px" : "undefined",
    }),

  pricebox: (isMobile: boolean) =>
    css({
      display: "flex",
      flexDirection: isMobile ? "row" : "column",
      justifyContent: isMobile ? "space-between" : undefined,
      alignItems: isMobile ? "center" : undefined,
      gap: "16px",
    }),

  button: (isMobile: boolean) =>
    css({
      width: !isMobile ? "100% !important" : undefined,
    }),

  longText: css({
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16px",
    textAlign: "justify",
  }),
}

export default styles
