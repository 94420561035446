import AppLoader from "@basset-la/components-commons/dist/components/AppLoader"
import { Suspense } from "react"
import AppTheme from "./AppTheme"
import { ConfigProvider } from "@basset-la/components-commons"
import { getAvailableProducts, getClientConfig } from "../api/agencies"

export interface Props {
  children?: React.ReactNode
}

const AppConfig: React.FC<Props> = ({ children }) => {
  return (
    <Suspense fallback={<AppLoader variant="CARS" />}>
      <ConfigProvider
        getClientConfig={getClientConfig}
        getAvailableProducts={getAvailableProducts}
        fallback={<AppLoader variant="CARS" />}
      >
        <AppTheme>{children}</AppTheme>
      </ConfigProvider>
    </Suspense>
  )
}

export default AppConfig
