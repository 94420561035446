import { Cluster } from "@basset-la/components-cars/dist/models"
import { CarResultsModel } from "../../utils/types/cars"
import qs from "qs"
import { FetchError } from "../../utils/types/error"
import { TFunction } from "react-i18next"

export interface CarResultsReducerAction {
  type: "set" | "append" | "error" | "hide_alert" | "reset_clusters" | "reset_all"
  payload?: CarResultsModel | FetchError
  t?: TFunction<string, any>
}

export const carResultsReducer = (results: CarResultsModel, action: CarResultsReducerAction): CarResultsModel => {
  const checkAlert = (clusters: Cluster[]) => {
    var view = false
    const offerPrice = qs.parse(window.location.search.substr(1)).offer_price || ""
    if (clusters.length > 0 && offerPrice) {
      const price = clusters[0].rates[0].fare.total
      const formattedPrice = price.toFixed(2)
      if (offerPrice !== formattedPrice) {
        view = true
      }
    }
    return view
  }

  switch (action.type) {
    case "error":
      return {
        ...results,
        error: action.payload as FetchError,
      }

    case "hide_alert":
      return {
        ...results,
        viewAlert: false,
      }

    case "reset_clusters":
      return {
        ...results,
        clusters: [],
        error: null,
      }

    case "reset_all":
      return {
        ...results,
        clusters: [],
        mappedFilters: undefined,
        error: null,
      }

    case "set":
      const res = action.payload as CarResultsModel

      const clusters = res && res.clusters ? res.clusters : []
      const viewAlert = checkAlert(clusters)
      return {
        clusters: clusters,
        filters: res.filters,
        mappedFilters:
          res && res.filters
            ? {
                carTypes: res.filters.categories.map((cat) => {
                  return { value: cat.value, label: action.t!(`CarFeatures.categoryType.${cat.value}`) }
                }),
                passengers: res.filters.passengers_quantity,
                companies: res.filters.providers,
                pickupPlaces: res.filters.pickups,
                transmissions: res.filters.transmission.map((tx) => {
                  return { value: tx.value, label: action.t!(`CarFeatures.${tx.label.toLowerCase()}`) }
                }),
                prices: res.filters.prices,
              }
            : undefined,
        total: res && res.total ? res.total : 0,
        viewAlert: viewAlert,
        error: null,
      }

    case "append":
      return {
        ...results,
        clusters: [...results.clusters, ...(action.payload as CarResultsModel).clusters],
      }
  }
}
