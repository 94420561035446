import { SearchboxParams, AppliedFilters } from "@basset-la/components-cars/dist/models"
import { mapParamsToUrl } from "../utils/helpers/mapper"
import { getSessionId } from "../utils/helpers/SessionStorage"
import { CarOrder, CarPagination, CarResponse, CarResultsModel } from "../utils/types/cars"
import { Config } from "@basset-la/components-commons/dist/models/config"
import { FetchError } from "../utils/types/error"

export const fetchClusters = async (
  params: SearchboxParams,
  filters: AppliedFilters,
  pagination: CarPagination,
  order: CarOrder,
  config: Config
): Promise<CarResultsModel> => {
  const filtersStr = `${mapParamsToUrl(params, filters, pagination, order)}&site=${config.country}&channel=WEB&source=${
    config.source
  }`

  const url = process.env.REACT_APP_CARS_API_URL
    ? `${process.env.REACT_APP_CARS_API_URL}/availabilities?${filtersStr}`
    : ""

  const results = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_API_KEY ? process.env.REACT_APP_API_KEY : "",
      "x-client-id": config.agency_id,
      "x-session-id": getSessionId(),
    },
  })
  const json = await results.json()

  if (!results.ok) {
    throw new FetchError(results.status, json.error)
  }
  return json
}

export const fetchDetails = async (rateId: string, config: Config): Promise<CarResponse> => {
  const queryStr = `site=${config.country}&channel=WEB&source=${config.source}`

  const url = process.env.REACT_APP_CARS_API_URL
    ? `${process.env.REACT_APP_CARS_API_URL}/availabilities/${rateId}?${queryStr}`
    : ""

  const results = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_API_KEY ? process.env.REACT_APP_API_KEY : "",
      "x-client-id": config.agency_id,
    },
  })
  const json = await results.json()

  if (!results.ok) {
    throw new FetchError(results.status, json.error)
  }
  return json
}
