import { FetchError } from "../utils/types/error"
import { UserAgencies } from "@basset-la/components-commons/dist/models/auth"

export const getUserAgencies = async (
  userId: string,
  authorization: string,
  clientId: string
): Promise<UserAgencies> => {
  const url = process.env.REACT_APP_AUTH_API_URL
    ? `${process.env.REACT_APP_AUTH_API_URL}/users/${encodeURIComponent(userId)}`
    : ""

  const headers = new Headers({
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${authorization}`,
    "x-client-id": clientId,
    "x-client-type": "AGENCY",
  })

  const results = await fetch(url, {
    method: "GET",
    headers: headers,
  })

  const json = await results.json()

  if (!results.ok) {
    throw new FetchError(results.status, json.error)
  }
  return json
}
