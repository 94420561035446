import React, { useEffect, useReducer, useRef, useState } from "react"
import ReactGA from "react-ga"
import { useTranslation } from "react-i18next"
import {
  parseAppliedFilters,
  parsePagination,
  mapParamsToUrl,
  parseSearchboxParams,
  parseOrder,
} from "../../utils/helpers/mapper"
import { I18N_NS, CLUSTERS_PER_PAGE } from "../../utils/constants"
import { I18N_NS as CARS_I18N_NS } from "@basset-la/components-cars/dist/utils"
import { useHistory, useLocation } from "react-router"
import Wrapper from "../Wrapper"
import { AppliedFilters, Rate } from "@basset-la/components-cars/dist/models"
import { SearchboxConfig, SearchboxParams } from "@basset-la/components-cars/dist/models/searchbox"
import { carResultsReducer } from "./reducer"
import { getRegion, getRegions } from "../../api/geo"
import Searchbox from "@basset-la/components-cars/dist/components/Searchbox"
import moment from "moment"
import { AdvertisingInfo } from "../../utils/types/advertising"
import { getAdvertising } from "../../api/advertising"
import { FetchError } from "../../utils/types/error"
import { CarOrder, CarPagination } from "../../utils/types/cars"
import { fetchClusters } from "../../api/cars"
import styles from "./Results.styles"
import { useTheme } from "@basset-la/themed-components"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Advertising from "@basset-la/components-commons/dist/components/Advertising"
import Alert from "@basset-la/components-commons/dist/components/Alert"
import Text from "@basset-la/components-commons/dist/components/Text"
import CarFilters from "@basset-la/components-cars/dist/components/CarFilters"
import ProductLoader from "@basset-la/components-commons/dist/components/ProductLoader"
import ErrorMessage from "../ErrorMessage/ErrorMessage"
import ClusterList from "./ClusterList"
import { useConfig } from "@basset-la/components-commons"

const Results: React.FC<{}> = () => {
  const { t, i18n } = useTranslation(I18N_NS)
  const { t: t_cars } = useTranslation(CARS_I18N_NS)
  const { search } = useLocation()
  const history = useHistory()
  const config = useConfig()

  const params = parseSearchboxParams(search)
  const [advertising, setAdvertising] = useState<AdvertisingInfo>()
  const [searchParams, setSearchParams] = useState<SearchboxParams | null>(null)
  const [appliedFilters, setAppliedFilters] = useState<AppliedFilters>(parseAppliedFilters(search))
  const [pagination, setPagination] = useState<CarPagination>(parsePagination(search))
  const [order, setOrder] = useState<CarOrder>(parseOrder(search))
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [lastRequestedPage, setLastRequestedPage] = useState(1)
  const [containerWidth, setContainerWidth] = useState(0)
  const containerRef = useRef(null)

  const theme = useTheme()

  const isMobile = useMediaQuery("(max-width: 1024px)")

  const [carResults, dispatchCarResults] = useReducer(carResultsReducer, {
    clusters: [],
    filters: {
      categories: [],
      passengers_quantity: [],
      transmission: [],
      pickups: [],
      providers: [],
      prices: [],
    },
    mappedFilters: undefined,
    total: 0,
    viewAlert: false,
    error: null,
  })

  useEffect(() => {
    if (containerRef) {
      setContainerWidth(containerRef.current!["clientWidth"] - 32)
    }
  }, [containerRef])

  useEffect(() => {
    document.title = t("Results.documentTitle")
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [t])

  useEffect(() => {
    const rebuildLocations = async () => {
      const pickupPromise = getRegion(
        params.pickupPlace?.iata_code!,
        params.pickupPlace?.type || "",
        config.config.language,
        config.config.agency_id,
        config.config.country
      )
      const dropoffPromise = getRegion(
        params.dropoffPlace?.iata_code!,
        params.dropoffPlace?.type || "",
        config.config.language,
        config.config.agency_id,
        config.config.country
      )
      const [pickup, dropoff] = await Promise.all([pickupPromise, dropoffPromise])
      params.pickupPlace = pickup
      params.dropoffPlace = dropoff
      setSearchParams({ ...params })
    }
    if (!searchParams) {
      rebuildLocations()
    }
  }, [searchParams, params, config])

  useEffect(() => {
    const loadAdvertising = async () => {
      try {
        const adv = await getAdvertising(config!.config)
        setAdvertising(adv)
      } catch (e) {
        console.log(e)
      }
    }

    loadAdvertising()
  }, [config])

  useEffect(() => {
    const loadClusters = async () => {
      try {
        const results = await fetchClusters(searchParams!, appliedFilters, pagination, order, config!.config)

        if (pagination.page === 1) {
          dispatchCarResults({ type: "set", payload: results, t: t_cars })
        } else {
          dispatchCarResults({ type: "append", payload: results })
        }
      } catch (e) {
        console.error(e)
        dispatchCarResults({ type: "error", payload: e as FetchError })
      } finally {
        setIsLoading(false)
      }
    }

    if (searchParams) {
      loadClusters()
    }
  }, [config, pagination, searchParams, appliedFilters, order, t_cars])

  const pushUrl = (sp: SearchboxParams, f: AppliedFilters, p: CarPagination, o: CarOrder, reset: boolean = false) => {
    const queryParams = mapParamsToUrl(sp, f, p, o)
    history.push({
      pathname: "/cars/results",
      search: queryParams,
    })

    setSearchParams(sp)
    setAppliedFilters(f)
    setPagination(p)
    setOrder(o)
    setLastRequestedPage(1)
    if (reset) {
      dispatchCarResults({ type: "reset_all" })
    } else {
      dispatchCarResults({ type: "reset_clusters" })
    }
    setIsLoading(true)
  }

  const handleSearch = (sp: SearchboxParams) => {
    const f: AppliedFilters = {
      carTypes: [],
      companies: [],
      passengers: [],
      pickupPlaces: [],
      price: undefined,
      transmissions: [],
    }
    const p: CarPagination = {
      offset: 0,
      limit: CLUSTERS_PER_PAGE,
      page: 1,
    }
    const o: CarOrder = {
      orderBy: "",
    }
    pushUrl(sp, f, p, o, true)
  }

  const handleFilterChange = (key: string, value: string[] | undefined) => {
    const f = { ...appliedFilters }
    f[key] = value

    const p: CarPagination = {
      offset: 0,
      limit: CLUSTERS_PER_PAGE,
      page: 1,
    }

    pushUrl({ ...searchParams! }, f, p, { ...order })
  }

  const handlePaginatedSearch = () => {
    const { offset, limit, page } = pagination as CarPagination
    if (!carResults.total || carResults.total > Number(offset) + Number(limit)) {
      const currentPage = page ? page + 1 : Number(offset) / CLUSTERS_PER_PAGE + 1
      if (currentPage > lastRequestedPage) {
        const p: CarPagination = {
          offset: offset + (limit && limit > CLUSTERS_PER_PAGE ? limit : CLUSTERS_PER_PAGE),
          limit: CLUSTERS_PER_PAGE,
          page: currentPage,
        }

        setLastRequestedPage(currentPage)
        setPagination(p)
        setIsLoading(true)
      }
    }
  }

  const handleViewDetails = (selectedRate: Rate) => {
    const qp = search.startsWith("?") ? search : `?${search}`

    window.open(`/cars/details/${selectedRate.id}${qp}`, "_blank")
  }

  const handleBannerClick = (url: string) => {
    window.open(url, "_blank")?.focus()
  }

  const handleCloseAlert = () => {
    dispatchCarResults({ type: "hide_alert" })
  }

  const searchboxConfig: SearchboxConfig = {
    minDate: moment().add(config!.config.search_configuration.min_days || 0, "days"),
    maxDate: moment().add(config!.config.search_configuration.max_days || 30, "days"),
    minAge: config!.config.search_configuration.min_driver_age || 14,
    maxAge: config!.config.search_configuration.max_driver_age || 99,
    minimumNights: 0,
    maximumNights: config!.config.search_configuration.range_days || 30,
  }
  const currencyCode =
    carResults.clusters && carResults.clusters.length > 0 ? carResults.clusters[0].rates[0].fare.currency : "ARS"

  return (
    <Wrapper>
      <div className={styles.root}>
        <div className={styles.container(theme)}>
          {searchParams && (
            <div className={styles.leftContainer}>
              <Searchbox
                getRegions={getRegions(i18n.language, config!.config.agency_id, config!.config.country)}
                config={searchboxConfig}
                params={searchParams!}
                onSearch={handleSearch}
              />
              {carResults.mappedFilters && appliedFilters && (
                <div className={styles.filters}>
                  <CarFilters
                    isMobile={isMobile}
                    currencyCode={currencyCode}
                    availableFilters={carResults.mappedFilters}
                    appliedFilters={appliedFilters}
                    onFilterChange={handleFilterChange}
                  />
                </div>
              )}
            </div>
          )}
          <div ref={containerRef} className={styles.rightContainer}>
            {carResults.error || (carResults.clusters.length === 0 && !isLoading) ? (
              <ErrorMessage title={t("Results.noResultsError.title")} message={t("Results.noResultsError.message")} />
            ) : (
              <>
                {carResults.clusters.length > 0 && advertising && advertising.top && containerWidth && (
                  <div className={styles.advertising}>
                    <Advertising width={`${containerWidth}px`} banner={advertising.top} onClick={handleBannerClick} />
                  </div>
                )}
                {!isLoading && carResults.viewAlert && (
                  <div className={styles.alertContainerStyle}>
                    <Alert onClose={handleCloseAlert}>
                      <Text size={14} variant="regular">
                        {t("Results.notAvailableOfferAlert")}
                      </Text>
                    </Alert>
                  </div>
                )}
                <div className={styles.clusterListContainer}>
                  {carResults.clusters.length > 0 && (
                    <>
                      {!isMobile && (
                        <div className={styles.clusterListHeader}>
                          <Text size={24} variant="regular">
                            {t("Results.itemsFound", { count: carResults.total })}
                          </Text>
                        </div>
                      )}
                      <ClusterList
                        isLoading={isLoading}
                        isMobile={isMobile}
                        clusters={carResults.clusters}
                        onPaginatedSearch={handlePaginatedSearch}
                        onViewDetails={handleViewDetails}
                      />
                    </>
                  )}
                  {isLoading && <ProductLoader is_mobile={isMobile} variant="WEB" product_variant="CARS_RESULT" />}
                </div>
                {carResults.clusters.length > 0 && advertising && advertising.bottom && containerWidth && (
                  <div className={styles.advertising}>
                    <Advertising
                      width={`${containerWidth}px`}
                      banner={advertising.bottom}
                      onClick={handleBannerClick}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Results
