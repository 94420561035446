import { FetchError } from "../utils/types/error"

export const getRegion = async (id: string, type: string, language: string, clientId: string, country: string) => {
  try {
    const url =
      process.env.REACT_APP_AUTOCOMPLETE_API_URL +
      `?entities=${type}&q=${encodeURIComponent(id)}&language=${
        language.split("-")[0]
      }&product=CARS&site=${country}&channel=WEB`

    const results = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY ? process.env.REACT_APP_API_KEY : "",
        "x-client-id": clientId,
      },
    })

    const json = await results.json()
    if (!results.ok) {
      throw new FetchError(results.status, json.error)
    }

    const region = json.find((r) => r.iata_code === id) || json[0]
    return region
  } catch (e) {}
}

export const getEntities = async (iata: string, language: string, clientId: string) => {
  if (!process.env.REACT_APP_GEO_URL) throw new Error("Missing key REACT_APP_GEO_URL")

  const lang = language.split("-")[0]
  const url = process.env.REACT_APP_GEO_URL + `/entities?iata_code=${iata}&language=${lang}`

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_API_KEY ? process.env.REACT_APP_API_KEY : "",
      "x-client-id": clientId,
    },
  })

  const json = await response.json()
  if (!response.ok) {
    throw new FetchError(response.status, json.error)
  }

  return json
}

export const getRegions = (language: string, clientId: string, site: string) => async (value: string) => {
  try {
    const url =
      process.env.REACT_APP_AUTOCOMPLETE_API_URL +
      `?entities=CITY,AIRPORT&q=${encodeURIComponent(value)}&language=${
        language.split("-")[0]
      }&product=CARS&site=${site}&channel=WEB`

    const results = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY ? process.env.REACT_APP_API_KEY : "",
        "x-client-id": clientId,
      },
    })

    const json = await results.json()
    if (!results.ok) {
      throw new FetchError(results.status, json.error)
    }
    return json
  } catch (e) {}
  return []
}
