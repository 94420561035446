import { Config } from "@basset-la/components-commons/dist/models/config"
import ls from "localstorage-ttl"

export const makeApiRequest = async (uri: string, lang: string) => {
  const config = await getClientConfig(lang)
  const opts = {
    method: "GET",
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY,
      "x-client-id": undefined,
    },
  }

  let qs = `site=${config.country}&channel=WEB`
  if (!uri.includes("?")) {
    qs = `?${qs}`
  } else {
    qs = `&${qs}`
  }
  return fetchAsync(`${uri}${qs}`, opts)
}

export const getAvailableProducts = async (agencyID: string): Promise<string[]> => {
  const appURI = process.env.REACT_APP_API_URI
  if (!appURI) throw new Error("Missing REACT_APP_API_URI")

  const uri = `${appURI}/agencies/${agencyID}/available-products?channel=WEB`
  const cacheKey = getClientProductsCacheKey()
  const cachedConfig = ls.get(cacheKey)

  if (cachedConfig != null) {
    return JSON.parse(cachedConfig)
  }
  const products = await fetchAsync(uri, { headers: { "x-api-key": process.env.REACT_APP_API_KEY } })

  saveAvailableProducts(products)

  return products
}

// TODO: Fix that => &product=CARS
export const getClientConfig = async (lng: string): Promise<Config> => {
  const appURI = process.env.REACT_APP_API_URI
  if (!appURI) throw new Error("Missing REACT_APP_API_URI")
  const language = lng.split("-")[0]

  const domain = process.env.NODE_ENV === "development" ? process.env.REACT_APP_DEV_DOMAIN : window.location.hostname
  const uri = `${appURI}/agencies/web-configuration?domain=${domain}&product=CARS&language=${language}`
  const cacheKey = getClientConfigCacheKey(language)
  const cachedConfig = ls.get(cacheKey)

  if (cachedConfig != null) {
    return JSON.parse(cachedConfig)
  }
  const config = await fetchAsync(uri, { headers: { "x-api-key": process.env.REACT_APP_API_KEY } })

  console.log(config)

  saveClientConfig(config, language)
  return config
}

const getClientConfigCacheKey = (lng: string) => {
  const domain = process.env.NODE_ENV === "development" ? process.env.REACT_APP_DEV_DOMAIN : window.location.hostname
  return `config_cars_${domain}_${lng}`
}

const getClientProductsCacheKey = () => {
  const domain = process.env.NODE_ENV === "development" ? process.env.REACT_APP_DEV_DOMAIN : window.location.hostname
  return "avail_products" + domain
}

const saveClientConfig = (config: Config, lng: string) => {
  const ttl = process.env.REACT_APP_THEME_TTL
  if (!ttl) throw new Error("Missing REACT_APP_THEME_TTL")
  ls.set(getClientConfigCacheKey(lng), JSON.stringify(config), parseInt(ttl, 10))
}

const saveAvailableProducts = (products: string[]) => {
  const ttl = process.env.REACT_APP_THEME_TTL
  if (!ttl) throw new Error("Missing REACT_APP_THEME_TTL")
  ls.set(getClientProductsCacheKey(), JSON.stringify(products), parseInt(ttl, 10))
}

const fetchAsync = async (url: string, options: any) => {
  const response = await fetch(url, options)
  const json = await response.json()
  return json
}

export const img = (path: string) => `${process.env.PUBLIC_URL}/img/${path}`
export const statics = (path: string) => `${process.env.REACT_APP_BASSET_STATIC_URL}/${path}`
