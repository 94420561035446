import Text from "@basset-la/components-commons/dist/components/Text"
import { useTheme } from "@basset-la/themed-components"
import styles from "./Card.styles"

export interface Props {
  children?: React.ReactNode
  imageUrl?: string
  title?: string
  width?: string
  fullHeight?: boolean
}

const Card: React.FC<Props> = ({ imageUrl, title, width, fullHeight, children }) => {
  const theme = useTheme()

  return (
    <div className={styles.root(fullHeight || false, width)}>
      <div className={styles.main}>
        {title && (
          <Text className={styles.title(theme)} size={16} variant="regular">
            {title}
          </Text>
        )}
        <div className={styles.content}>{children}</div>
      </div>
      {imageUrl && <div className={styles.image(imageUrl)}></div>}
    </div>
  )
}

export default Card
