import * as React from "react"
import { useEffect } from "react"

interface Props {
  isLoading: boolean
  onPaginatedSearch: () => void
  children: React.ReactNode
}

const InfiniteScroll: React.FC<Props> = ({ isLoading, onPaginatedSearch, children }) => {
  useEffect(() => {
    const onScroll = () => {
      let body = document.body,
        html = document.documentElement
      let documentHeight = Math.max(
        body ? body.scrollHeight : 0,
        body ? body.offsetHeight : 0,
        html ? html.clientHeight : 0,
        html ? html.scrollHeight : 0,
        html ? html.offsetHeight : 0
      )

      let windowHeight = window.innerHeight
      let scrollBottom = windowHeight + window.scrollY
      let triggerBottom = scrollBottom + 2700 // 2700px from the bottom to request next page

      if (triggerBottom >= documentHeight && !isLoading) {
        onPaginatedSearch()
      }
    }

    window.addEventListener("scroll", onScroll, false)

    return () => {
      window.removeEventListener("scroll", onScroll, false)
    }
  }, [isLoading, onPaginatedSearch])

  return <>{children}</>
}

export default InfiniteScroll
