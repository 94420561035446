import ReactDOM from "react-dom"
import AppRoutes from "./components/AppRoutes"
import reportWebVitals from "./reportWebVitals"
import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"
import "./index.css"
import "./i18n/i18n"

ReactDOM.render(<AppRoutes />, document.getElementById("root"))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
