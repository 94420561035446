import { css } from "@emotion/css"

const styles = {
  container: (homeImageUrl: string) =>
    css({
      display: "flex",
      justifyContent: "space-around",
      width: "100%",
      padding: "64px",
      background: `url('${homeImageUrl}') no-repeat center center`,
      backgroundSize: "cover",
      "@media(max-width: 1024px)": {
        padding: "32px",
      },
    }),
}

export default styles
