import React from "react"
import styles from "./ErrorMessage.styles"
import { useTheme } from "@basset-la/themed-components"
import Text from "@basset-la/components-commons/dist/components/Text"
import Button from "@basset-la/components-commons/dist/components/Button"
import CarIcon from "@material-ui/icons/DirectionsCar"

export interface Props {
  title?: string
  message?: string
  buttonText?: string
  onButtonClick?: () => void
}

const ErrorMessage: React.FC<Props> = ({ title, message, buttonText, onButtonClick }) => {
  const theme = useTheme()

  const handleButtonClick = () => {
    if (onButtonClick) {
      onButtonClick()
    }
  }

  return (
    <div className={styles.root(theme)}>
      <div>
        <CarIcon className={styles.image(theme)} />
      </div>
      <div className={styles.content}>
        {title && (
          <Text className={styles.title(theme)} size={24} component="p" variant="bold">
            {title}
          </Text>
        )}
        {message && (
          <Text size={18} component="p">
            {message}
          </Text>
        )}
        {buttonText && (
          <Button variant="contained" onClick={handleButtonClick}>
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  )
}

export default ErrorMessage
