import { createBrowserHistory } from "redux-first-routing"
import { Router, Switch, Route } from "react-router-dom"
import AppConfig from "./AppConfig"
import Home from "./Home/Home"
import Results from "./Results/Results"
import Details from "./Details/Details"
import { Auth0Provider } from "@auth0/auth0-react"

const history = createBrowserHistory()

const AppRoutes: React.FC = () => {
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN || ""}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ""}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE || ""}
    >
      <Router history={history}>
        <Switch>
          <Route path="/cars/results">
            <AppConfig>
              <Results />
            </AppConfig>
          </Route>

          <Route path="/cars/details/:carRateId/">
            <AppConfig>
              <Details />
            </AppConfig>
          </Route>

          <Route path="/cars">
            <AppConfig>
              <Home />
            </AppConfig>
          </Route>
        </Switch>
      </Router>
    </Auth0Provider>
  )
}

export default AppRoutes
