import React, { useEffect } from "react"
import ReactGA from "react-ga"
import styles from "./Home.styles"
import Searchbox from "@basset-la/components-cars/dist/components/Searchbox"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import Wrapper from "../Wrapper"
import { useHistory } from "react-router"
import { getRegions } from "../../api/geo"
import { SearchboxConfig, SearchboxParams } from "@basset-la/components-cars/dist/models/searchbox"
import { mapParamsToUrl } from "../../utils/helpers/mapper"
import { useConfig } from "@basset-la/components-commons"

interface Props {}

const Home: React.FC<Props> = () => {
  const { i18n, t } = useTranslation(I18N_NS)
  const history = useHistory()
  const config = useConfig()

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
    document.title = t("Home.documentTitle")
  }, [t])

  const handleSubmitSearch = (params: SearchboxParams) => {
    const queryParams = mapParamsToUrl(params)
    history.push({
      pathname: "/cars/results",
      search: queryParams,
    })
  }

  const homeImageUrl = config!.config.home_image_url || "https://statics.basset.la/cars/bg-2.png"
  const searchboxConfig: SearchboxConfig = {
    minDate: moment().add(config!.config.search_configuration.min_days || 0, "days"),
    maxDate: moment().add(config!.config.search_configuration.max_days || 30, "days"),
    minAge: config!.config.search_configuration.min_driver_age || 14,
    maxAge: config!.config.search_configuration.max_driver_age || 99,
    minimumNights: 0,
    maximumNights: config!.config.search_configuration.range_days || 30,
  }

  return (
    <Wrapper>
      <div className={styles.container(homeImageUrl)}>
        <Searchbox
          getRegions={getRegions(i18n.language, config!.config.agency_id, config!.config.country)}
          config={searchboxConfig}
          params={{
            pickupPlace: null,
            pickupDate: null,
            dropoffPlace: null,
            dropoffDate: null,
            driverAge: 0,
          }}
          wide={true}
          onSearch={handleSubmitSearch}
        />
      </div>
    </Wrapper>
  )
}

export default Home
