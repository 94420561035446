import { AppliedFilters, SearchboxParams } from "@basset-la/components-cars/dist/models"
import { AUTOMATIC_TRANSMISSION, MANUAL_TRANSMISSION, CLUSTERS_PER_PAGE } from "../constants"
import { CarOrder, CarPagination } from "../types/cars"
import qs from "qs"
import { AutocompleteItem, AutocompleteItemType } from "@basset-la/components-products"
import moment from "moment"

// site, channel, language, source

export const mapParamsToUrl = (
  search: SearchboxParams,
  filters?: AppliedFilters,
  pagination?: CarPagination,
  order?: CarOrder
) => {
  const searchReq = {
    pickup_location: `${search.pickupPlace?.iata_code || ""}-${search.pickupPlace?.type || ""}`,
    dropoff_location: `${search.dropoffPlace?.iata_code || ""}-${search.dropoffPlace?.type || ""}`,
    pickup_date: search.pickupDate?.format("YYYY-MM-DDTHH:mm") || "",
    dropoff_date: search.dropoffDate?.format("YYYY-MM-DDTHH:mm") || "",
    driver_age: search.driverAge,
  }

  const filtersReq = {
    category: filters && filters.carTypes && filters.carTypes.length > 0 ? filters.carTypes.join(",") : undefined,
    provider: filters && filters.companies && filters.companies.length > 0 ? filters.companies.join(",") : undefined,
    passengers:
      filters && filters.passengers && filters.passengers.length > 0 ? filters.passengers.join(",") : undefined,
    pickup:
      filters && filters.pickupPlaces && filters.pickupPlaces.length > 0 ? filters.pickupPlaces.join(",") : undefined,
    automatic_transmission:
      filters && filters.transmissions && filters.transmissions.length === 1
        ? filters.transmissions.join(",")
        : undefined,
    price:
      filters && filters.price && filters.price.length > 0 ? filters.price.map((p) => `${p}`).join(",") : undefined,
  }

  const paginationReq = {
    limit: pagination ? pagination.limit : undefined,
    offset: pagination ? pagination.offset : undefined,
  }

  const orderReq = {
    order_by: order ? order.orderBy : undefined,
  }

  const query = qs.stringify(
    {
      ...searchReq,
      ...filtersReq,
      ...paginationReq,
      ...orderReq,
    },
    { encode: false }
  )

  return query
}

const parseLocation = (location: string): AutocompleteItem => {
  const parts = location.split("-")
  return {
    id: "",
    name: "",
    iata_code: parts[0],
    type: parts[1] as AutocompleteItemType,
  }
}

export const parseSearchboxParams = (search: string): SearchboxParams => {
  const obj = qs.parse(search, { ignoreQueryPrefix: true })

  const pickupPlace = obj["pickup_location"] ? parseLocation(obj["pickup_location"] as string) : null
  const dropoffPlace = obj["dropoff_location"] ? parseLocation(obj["dropoff_location"] as string) : null
  const pickupDate = obj["pickup_date"] ? moment(obj["pickup_date"] as string) : null
  const dropoffDate = obj["dropoff_date"] ? moment(obj["dropoff_date"] as string) : null
  const driverAge = obj["driver_age"] ? parseInt(obj["driver_age"] as string) : 0

  return {
    pickupPlace: pickupPlace,
    dropoffPlace: dropoffPlace,
    pickupDate: pickupDate,
    dropoffDate: dropoffDate,
    driverAge: driverAge,
  }
}

export const parseAppliedFilters = (search: string): AppliedFilters => {
  const obj = qs.parse(search, { ignoreQueryPrefix: true })

  const category = obj["category"] ? (obj["category"] as string).split(",") : []
  const provider = obj["provider"] ? (obj["provider"] as string).split(",") : []
  const passengers = obj["passengers"] ? (obj["passengers"] as string).split(",") : []
  const pickup = obj["pickup"] ? (obj["pickup"] as string).split(",") : []
  const automatic_transmission = obj["automatic_transmission"]
    ? ((obj["automatic_transmission"] as string)
        .split(",")
        .map((t) => (t === "true" ? AUTOMATIC_TRANSMISSION : MANUAL_TRANSMISSION)) as string[])
    : []
  const price = obj["price"] ? (obj["price"] as string).split(",").map((n) => parseInt(n)) : undefined

  return {
    carTypes: category,
    companies: provider,
    passengers: passengers,
    pickupPlaces: pickup,
    price: price as [number, number],
    transmissions: automatic_transmission,
  }
}

export const parsePagination = (search: string): CarPagination => {
  const obj = qs.parse(search, { ignoreQueryPrefix: true })

  const limit = obj["limit"] ? parseInt(obj["limit"] as string) : CLUSTERS_PER_PAGE
  const offset = obj["offset"] ? parseInt(obj["offset"] as string) : 0
  const page = offset / limit + 1

  return {
    limit: limit,
    offset: offset,
    page: page,
  }
}

export const parseOrder = (search: string): CarOrder => {
  const obj = qs.parse(search, { ignoreQueryPrefix: true })

  return {
    orderBy: (obj["order_by"] as string) || "",
  }
}
