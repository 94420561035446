import { css } from "@emotion/css"

const styles = {
  root: css({
    display: "flex",
    justifyContent: "center",
  }),

  main: css({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "1344px",
  }),
}

export default styles
